import React, { Component } from "react"
import jwt, { decode } from "jsonwebtoken"

import axios from "axios"

import "../../components/Styles/Login.scss"
import NotificationPopup from "../../components/NotificationPopup"
let apiURL = "https://api.globelynx.com/api/"

export default class ResetPasswordPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notification: false,
      notificationmsg: "",
      notificationstatus: "success",
      email: "",
      _id: "",
      firstName: "",
      lastName: "",
      password: "",
    }

    this.submit = this.submit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.showNotification = this.showNotification.bind(this)
  }

  showNotification() {
    this.setState((prevState) => ({
      notification: !prevState.notification,
    }))

    setTimeout(
      function() {
        this.setState({ notification: false })
      }.bind(this),
      2000
    )
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submit(e) {
    e.preventDefault()
    axios
      .put(apiURL + "users/" + this.state._id, {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        this.showNotification()
        this.setState({
          notificationmsg: "Success! User has been edited.",
          notificationstatus: "success",
        })

        // notifier.success("Success! User has been edited.");
        window.location.href = "https://globelynx.com/"
      })
      .catch((err) => {
        this.showNotification()
        this.setState({
          notificationmsg: "Error! Something went wrong.",
          notificationstatus: "error",
        })
        // notifier.error("Error! Something went wrong.");
      })
  }

  componentDidMount() {
    var url = null
    var token = null
    if (window.location.href) {
      var getUrl = window.location
      var baseUrl =
        getUrl.protocol +
        "//" +
        getUrl.host +
        "/" +
        getUrl.pathname.split("/")[1]
      url = new URL(window.location.href, baseUrl)
      token = url.searchParams.get("token")
    }
    jwt.verify(
      token,
      "0a6b944d-d2fb-46fc-a85e-0295c986cd9f",
      (err, decoded) => {
        if (decoded !== undefined) {
          this.setState({
            email: decoded.email,
            _id: decoded._id,
            firstName: decoded.firstName,
            lastName: decoded.lastName,
            password: decoded.password,
          })
        } else {
          this.showNotification()
          this.setState({
            notificationmsg: "Error! Something went wrong.",
            notificationstatus: "error",
          })
          // notifier.error("Error! Something went wrong.");
        }
      }
    )
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.notification ? "block" : "none" }}>
          <NotificationPopup status={this.state.notificationstatus}>
            {this.state.notificationmsg}
          </NotificationPopup>
        </div>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form"
                method="post"
                onSubmit={this.submit}
              >
                <span className="login100-form-title p-b-26">
                  Reset Password
                </span>
                <span className="login100-form-title p-b-48">
                  <i className="zmdi zmdi-font"></i>
                </span>

                <h3 style={{ textAlign: "center" }}>{this.state.email}</h3>

                <div className="wrap-input100 validate-input">
                  <span className="btn-show-pass">
                    <i className="zmdi zmdi-eye"></i>
                  </span>
                  <input
                    className="input100"
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="New Password"
                  />
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <input
                      className="login100-form-btn"
                      type="submit"
                      value="Reset New Password"
                    />
                  </div>
                </div>

                {/* <div style={{textAlign:'center'}}>
              <span className="txt1">
                Don’t have an account?
              </span>
  
              <span className="txt2" style={{cursor:'pointer'}} >
                Sign Up
              </span>
            </div> */}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
